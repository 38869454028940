<template>
  <div
    v-if="loading"
    class="usage-table--loading mb-8">
    <v-progress-circular
      color="secondary"
      indeterminate/>
  </div>
  <div
    v-else-if="_.isEmpty(projects)"
    class="usage-table__no-data mb-8">
    <h3
      class="usage-table__no-data-title"
      v-text="$t('noMatchingProjects')"/>
    <p
      class="usage-table__no-data-text"
      v-text="$t('noMatchingProjectsHint')"/>
  </div>
  <v-data-table
    v-else
    class="mb-8"
    :headers="$static.headers"
    :items="formatProjects(projects)"
    disable-pagination
    hide-default-footer>
    <template #[`item.actions`]="{ item }">
      <router-link
        :to="{ name: 'project', params: { projectCanonical: _.get(item, 'canonical') } }"
        target="_blank">
        <v-icon color="primary">
          open_in_new
        </v-icon>
      </router-link>
    </template>
    <template #foot>
      <tfoot v-if="quota">
        <tr>
          <td>
            <b v-text="$t('totalUsage')"/>
          </td>
          <td class="py-3">
            <div class="resource-total">
              <b v-text="$tc('quotas.nCores', _.sumBy(projects, 'cpu'))"/>
            </div>
            <div class="resource-usage">
              {{ $t('quotas.usedOf.short.cores', { used: getResourceUsage('cpu'), of: quota.cpu }) }}
            </div>
          </td>
          <td>
            <div class="resource-total">
              <b v-text="formatBytes(_.sumBy(projects, 'memory'))"/>
            </div>
            <div class="resource-usage">
              {{ $t('quotas.usedOf.short.bytes', { used: getResourceUsage('memory'), of: formatBytes(quota.memory) }) }}
            </div>
          </td>
          <td>
            <div class="resource-total">
              <b v-text="formatBytes(_.sumBy(projects, 'storage'))"/>
            </div>
            <div class="resource-usage">
              {{ $t('quotas.usedOf.short.bytes', { used: getResourceUsage('storage'), of: formatBytes(quota.storage) }) }}
            </div>
          </td>
          <td/>
        </tr>
      </tfoot>
    </template>
  </v-data-table>
</template>

<script>
import { formatBytes, getUsagePercentage } from '@/utils/helpers/quotas'

export default {
  name: 'CyQuotasUsageTable',
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    quota: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    $static () {
      return {
        headers: [
          {
            text: this.$t('Project'),
            value: 'name',
          },
          {
            text: this.$t('quotas.cpu'),
            value: 'cpu',
            sortable: false,
            width: '200px',
          },
          {
            text: this.$t('quotas.memory'),
            value: 'memory',
            sortable: false,
            width: '200px',
          },
          {
            text: this.$t('quotas.storage'),
            value: 'storage',
            sortable: false,
            width: '200px',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
            width: '64px',
          },
        ],
      }
    },
  },
  methods: {
    formatBytes,
    formatProjects (projects) {
      return projects.map(({ project, cpu, memory, storage }) => ({
        name: project.name,
        canonical: project.canonical,
        cpu: this.$tc('quotas.nCores', cpu),
        memory: formatBytes(memory),
        storage: formatBytes(storage),
      }))
    },
    getResourceUsage (type) {
      return `${getUsagePercentage({
        used: _.sumBy(this.projects, type),
        allocated: this.quota[type],
      })}%`
    },
  },
  i18n: {
    messages: {
      en: {
        totalUsage: 'Total usage',
        noMatchingProjects: 'There is no project consuming this quota currently',
        noMatchingProjectsHint: 'Once you start creating projects with this quota, their consumption will appear here',
      },
      es: {
        totalUsage: 'Uso total',
        noMatchingProjects: 'No hay ningún proyecto que consuma esta quota actualmente',
        noMatchingProjectsHint: 'Una vez que comiences a crear proyectos con esta quota, su consumo aparecerá aquí',
      },
      fr: {
        totalUsage: 'Utilisation totale',
        noMatchingProjects: `Aucun projet n'utilise ce quota actuellement`,
        noMatchingProjectsHint: 'Une fois que vous aurez commencé à créer des projets avec ce quota, leur consommation apparaîtra ici',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table {
  width: 100%;
  border: 1px solid cy-get-color("grey", "light-2");

  tfoot {
    background-color: cy-get-color("grey", "light-4");

    td {
      b {
        color: cy-get-color("primary");
      }

      .resource-usage {
        color: cy-get-color("grey", "dark-2");
      }
    }
  }
}

.usage-table {
  &__no-data {
    @extend %dashed-border;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 256px;

    &-title {
      color: cy-get-color("primary", "light-2");
    }

    &-text {
      color: cy-get-color("primary", "light-3");
    }
  }
}

.usage-table--loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 256px;
}
</style>
