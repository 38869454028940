import { render, staticRenderFns } from "./quota.vue?vue&type=template&id=5b27d381&scoped=true"
import script from "./quota.vue?vue&type=script&lang=js"
export * from "./quota.vue?vue&type=script&lang=js"
import style0 from "./quota.vue?vue&type=style&index=0&id=5b27d381&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b27d381",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VDivider,VTextField})
